 <template>
     <v-card>
         <v-form @submit="UpdateEditedBudget($v.editBudget)">
             <v-app-bar dense absolute text class="fixedToolbar" style="z-index: 2;">
                 <v-toolbar-title v-bind:class="{'headline grey-text': !drawer, 'padLeft headline grey-text': drawer }">Edit Budget</v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-btn icon type="submit" v-on:click.prevent="UpdateEditedBudget($v.editBudget)">
                     <v-icon color="green">fas fa-check</v-icon>
                 </v-btn>
                 <v-btn icon @click.native="$router.push({ path: '/dashboard/budget' })">
                     <v-icon color="red">fas fa-times</v-icon>
                 </v-btn>
             </v-app-bar>

             <v-card-text style="padding-top: 0;">
                 <v-container grid-list-md class="padContentLess">
                     <v-layout wrap>
                         <v-flex x12>
                             <v-autocomplete v-bind:items="curriencies"
                                             v-model="editBudget.currency"
                                             label="Currency"
                                             chips
                                             hint="What Currency is this Income in?"
                                             persistent-hint
                                             :error-messages="currencyErrors"
                                             @input="$v.editBudget.currency.$touch()"
                                             @blur="$v.editBudget.currency.$touch()"
                                             item-text="currencyName"
                                             item-value="currencyShort">
                                 <template slot="selection"
                                           slot-scope="data">
                                     <v-chip :input-value="data.selected"
                                             class="chip--select-multi">
                                         {{ data.item.currencyName }}
                                     </v-chip>
                                 </template>
                                 <template slot="item"
                                           slot-scope="data">

                                     <template>
                                         <v-list-item-content>
                                             <v-list-item-title v-html="data.item.currencyName"></v-list-item-title>
                                             <v-list-item-subtitle v-html="data.item.currencyShort"></v-list-item-subtitle>
                                         </v-list-item-content>
                                     </template>
                                 </template>
                             </v-autocomplete>
                         </v-flex>
                         <v-flex xs12>
                             <v-text-field label="Budget Name" v-model="editBudget.budgetName"
                                           :error-messages="budgetNameErrors"
                                           @input="$v.editBudget.budgetName.$touch()"
                                           @blur="$v.editBudget.budgetName.$touch()"></v-text-field>
                         </v-flex>
                         <v-flex xs12>
                             <v-select v-bind:items="account"
                                       v-model="editBudget.account"
                                       label="Account"
                                       chips
                                       prepend-icon="fa-balance-scale"
                                       hint="What Account does this belong to?"
                                       persistent-hint
                                       :error-messages="accountErrors"
                                       @input="$v.editBudget.account.$touch()"
                                       @blur="$v.editBudget.account.$touch()"></v-select>
                         </v-flex>
                         <v-flex xs12>
                             <Category v-bind:sentCategory="editBudget.category"
                                       v-bind:options="{prepend: 'fa-tags', hint: true}"
                                       v-on:category="editBudget.category = $event"></Category>
                             <!--<v-select v-bind:items="category"
                  v-model="editBudget.category"
                  label="Category"
                  chips
                  prepend-icon="fa-tags"
                  hint="What Category does this belong to?"
                  persistent-hint
                  :error-messages="categoryErrors"
                  @input="$v.editBudget.category.$touch()"
                  @blur="$v.editBudget.category.$touch()"></v-select>-->
                         </v-flex>
                         <v-flex xs12>
                             <v-select v-bind:items="type"
                                       v-model="editBudget.budgetType"
                                       label="budget Type"
                                       chips
                                       prepend-icon="fa-tag"
                                       hint="What Budget Type is this?"
                                       persistent-hint
                                       :error-messages="budgetTypeErrors"
                                       @input="$v.editBudget.budgetType.$touch()"
                                       @blur="$v.editBudget.budgetType.$touch()"></v-select>
                         </v-flex>
                         <v-flex xs12>
                             <v-text-field label="Budget Amount" prepend-icon="fa-money-bill-alt" type="number" min="1"
                                           v-model="editBudget.budgetAmount"
                                           :error-messages="budgetAmountErrors"
                                           @change="copyBudgetAmountToRefillAmount()"
                                           @input="$v.editBudget.budgetAmount.$touch()"
                                           @blur="$v.editBudget.budgetAmount.$touch()"></v-text-field>
                         </v-flex>
                         <v-flex xs12>
                             <v-text-field label="Refill Amount" prepend-icon="fa-money-bill-alt" type="number" min="1"
                                           v-model="editBudget.refillAmount"
                                           :error-messages="refillAmountErrors"
                                           @input="$v.editBudget.refillAmount.$touch(), stopCopyBudgetAmountToRefillAmount()"
                                           @blur="$v.editBudget.refillAmount.$touch()"></v-text-field>
                         </v-flex>
                         <v-flex xs12>
                             <v-text-field label="Comment" prepend-icon="fa-comment" v-model="editBudget.comment"></v-text-field>
                         </v-flex>
                         <v-flex xs12>
                             <v-divider></v-divider>
                         </v-flex>

                         <!-- Notifications -->

                         <v-flex xs12><label><b>Budget Overspent</b></label></v-flex>

                         <v-flex xs10 lg4>
                             <p>Notify me when amount spent has exceeded budgeted amount</p>
                         </v-flex>
                         <v-flex xs2 lg8>
                             <v-switch v-model="editBudget.budgetOverspent"></v-switch>
                         </v-flex>


                         <v-flex xs12><label><b>Overspending Risk</b></label></v-flex>

                         <v-flex xs10 lg4>
                             <p>Notify me when there is a risk of overspending</p>
                         </v-flex>
                         <v-flex xs2 lg8>
                             <v-switch v-model="editBudget.overspendingRisk"></v-switch>
                         </v-flex>


                     </v-layout>
                 </v-container>
             </v-card-text>
         </v-form>
     </v-card>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, minValue } from 'vuelidate/lib/validators'

  import { globalFunctions } from '../../javascript/globalFunctions'

  export default {
    mixins: [validationMixin],
    components: {
        Category: () => import('../../components/category/categorySelect.vue')
    },
    props: ['drawer'],
    data() {
      return {
        account: [],
        category: [],
        type: ["None", "Weekly", "Monthly", "Yearly"],
        curriencies: [],
        copyToRefill: true,
        editBudget: {
          id: 0,
          budgetName: '',
          account: '',
          budgetType: '',
          budgetAmount: 0,
          amountSpent: 0,
          refillAmount: 0,
          //refillDate: '',
          currency:'',
          comment: '',
          category: '',

          //Notification
          budgetOverspent: true,
          overspendingRisk: true,

          //State of Current Object
          objectState: ''
        },
        items: []
      }
    },
    computed: {
      budgetNameErrors() {
        const errors = []
        if (!this.$v.editBudget.budgetName.$dirty) return errors
        !this.$v.editBudget.budgetName.required && errors.push('Budget Name is required')
        return errors
      },
      accountErrors() {
        const errors = []
        if (!this.$v.editBudget.account.$dirty) return errors
        !this.$v.editBudget.account.required && errors.push('Account is required')
        return errors
      },
      budgetTypeErrors() {
        const errors = []
        if (!this.$v.editBudget.budgetType.$dirty) return errors
        !this.$v.editBudget.budgetType.required && errors.push('Budget Type is required')
        return errors
      },
      //categoryErrors() {
      //  const errors = []
      //  if (!this.$v.editBudget.category.$dirty) return errors
      //  !this.$v.editBudget.category.required && errors.push('Category is required')
      //  return errors
      //},
      currencyErrors() {
        const errors = []
        if (!this.$v.editBudget.currency.$dirty) return errors
        !this.$v.editBudget.currency.required && errors.push('Currency is required')
        return errors
      },
      budgetAmountErrors() {
        const errors = []
        if (!this.$v.editBudget.budgetAmount.$dirty) return errors
        !this.$v.editBudget.budgetAmount.minValue && errors.push('Budget Amount must be at least 1')
        return errors
      },
      refillAmountErrors() {
        const errors = []
        if (!this.$v.editBudget.budgetAmount.$dirty) return errors
        !this.$v.editBudget.budgetAmount.minValue && errors.push('Refill Amount must be at least 1')
        return errors
      },
      copyBudgetAmountToRefillAmount() {
        if (this.editBudget.refillAmount === 0 || this.editBudget.refillAmount === '') {
          this.copyToRefill = true
        }

        if (this.copyToRefill === true) {
          this.editBudget.refillAmount = this.editBudget.budgetAmount
        }

      },
      stopCopyBudgetAmountToRefillAmount() {
        this.copyToRefill = false
      }
    },
    methods: {
      budgetCheck: function ($v) {
        $v.$touch(); //Triggers Error Messages

        if (this.editBudget.budgetAmount <= 0 || !globalFunctions.isIntOrFloat(this.editBudget.budgetAmount) || this.editBudget.budgetAmount === "") { this.editBudget.budgetAmount = 0 }

        if ($v.budgetName.$invalid || $v.account.$invalid) {
          return false
        }

        //for (let i = 0; i < this.items.length; i++) {
        //  if (this.items[i].budgetName.toLowerCase() === this.currentBudget.budgetName.toLowerCase()) {
        //    this.sameBudget = true
        //    return false
        //  }
        //  if (i === this.items.length - 1) {
        //    this.sameBudget = false
        //  }
        //}

        return true
      },
      UpdateEditedBudget: function ($v) {
        if (this.budgetCheck($v)) {
          this.editDialog = false

          this.editBudget.objectState = "Update"

          this.$store.commit('updateBudgetinStore', this.editBudget)

          this.$router.push({ path: '/dashboard/budget' })
        }
      },
      EditBudget: function (item) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].id === parseInt(item)) {
            let editEntry = JSON.parse(JSON.stringify(this.items[i]))
            this.editBudget = editEntry
            break
          }
        }

        this.editDialog = true
        this.copyToRefill = false
      },
      getAvailableCurriencies: function () {
        let cur = this.$store.state.Currencies

        for (let i = 0; i < cur.length; i++) {
          if (cur[i].ObjectState !== "Delete") {
            this.curriencies.push(cur[i])
          }
        }
      }
    },
    beforeMount() {
      this.items = JSON.parse(JSON.stringify(this.$store.state.Budgets))

      let cat = this.$store.state.Categories
      for (let i = 0; i < cat.length; i++) {
        this.category.push(cat[i].categoryName)
      }

      let acc = this.$store.state.Accounts
      for (let i = 0; i < acc.length; i++) {
        this.account.push(acc[i].accountName)
      }

      this.getAvailableCurriencies()

      this.EditBudget(this.$route.params.itemId)
    },
    validations: {
      editBudget: {
        budgetName: {
          required
        },
        account: {
          required
        },
        //category: {
        //  required
        //},
        currency: {
          required
        },
        budgetType: {
          required
        },
        budgetAmount: {
          required,
          minValue: minValue(1)
        },
        refillAmount: {
          required,
          minValue: minValue(1)
        },
        refillDate: {
          required
        }
      }
    }

  }
</script>
